import {css} from 'lit';

export default css`
  p,
  span,
  strong,
  em {
    line-height: 1.5;
  }
`;
